import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VDivider,{staticClass:"mt-4"}),_c(VCardText,{staticClass:"d-flex align-center flex-wrap pb-0"},[_c(VTextField,{staticClass:"user-search me-3 mb-4",attrs:{"append-icon":_vm.icons.mdiMagnify,"placeholder":"Buscar por nombre de usuario y empresa","outlined":"","hide-details":"","dense":""},on:{"keyup":_vm.handleSearch},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}}),_c(VSpacer),_c(VSpacer),_c('div',{staticClass:"d-flex align-center flex-wrap"},[_c(VBtn,{staticClass:"mb-4 me-3",attrs:{"color":"primary"},on:{"click":function($event){return _vm.$refs.patient_form_modal.openModal()}}},[_c(VIcon,[_vm._v(_vm._s(_vm.icons.mdiPlus))]),_c('span',[_vm._v("Agregar Paciente")])],1)],1)],1),_c(VDataTable,{staticClass:"table-kitchen-sink",attrs:{"headers":_vm.tableColumns,"items":_vm.patientsListTable,"search":_vm.search,"page":_vm.current_page,"server-items-length":_vm.totalPatientsListTable,"loading":_vm.loading,"footer-props":{
      'items-per-page-options': [10, 20, 50],
      'items-per-page-text': 'Items por página',
      'page-text': '{0}-{1} de {2}'
    }},on:{"update:page":function($event){return _vm.changingPatientsPage($event)},"update:items-per-page":function($event){return _vm.changingPatientsItemsPerPage($event)}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c(VListItem,[_c(VListItemTitle,[_vm._v(" No se encontraron "),_c('strong',[_vm._v("pacientes")])])],1)]},proxy:true},{key:"item.name",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c(VAvatar,{staticClass:"v-avatar-light-bg primary--text ma-2",attrs:{"color":"primary","size":"50"},on:{"click":function($event){return _vm.$refs.patient_details_modal.openModal(item.id)}}},[(item.profile_pic)?_c(VImg,{attrs:{"src":item.profile_pic}}):_c('span',[_vm._v(_vm._s('?'))])],1),_c('div',{staticClass:"d-flex flex-column ms-3",on:{"click":function($event){return _vm.$refs.patient_details_modal.openModal(item.id)}}},[_c('span',{staticClass:"d-block font-weight-semibold text--primary text-truncate"},[_vm._v(_vm._s(item.name))]),_c('small',[_vm._v(_vm._s(item.specie.label))])])],1)]}},{key:"item.tutors",fn:function(ref){
    var item = ref.item;
return [(item.tutors.length)?_c('div',_vm._l((item.tutors),function(tutor,index){return _c(VChip,{key:index,staticClass:"ms-1",attrs:{"small":"","outlined":""}},[_vm._v(" "+_vm._s(tutor.name)+" ")])}),1):_c('div',{staticClass:"ms-8"},[_vm._v(" - ")])]}},{key:"item.files",fn:function(ref){
    var item = ref.item;
return [_c(VBtn,{attrs:{"elevation":"2","x-small":"","outlined":""},on:{"click":function($event){return _vm.$refs.patient_files_modal.openModal(item.id)}}},[_vm._v(" Archivos ")])]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c(VIcon,{staticClass:"me-2",attrs:{"color":"primary"},on:{"click":function($event){return _vm.$refs.patient_form_modal.openModal(item.id)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiPencilOutline)+" ")]),_c(VIcon,{attrs:{"color":"error"},on:{"click":function($event){return _vm.deleteModal(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiDeleteOutline)+" ")])]}}],null,true)}),_c(VDialog,{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c(VCard,[_c(VCardTitle,[_vm._v(" Eliminar Paciente? "),_c('b',{staticClass:"ps-3"},[_vm._v(_vm._s(("'" + (_vm.editedItem.name) + "'")))])]),_c(VCardSubtitle,{staticClass:"mt-3"},[_vm._v(" Tenga en cuenta que los datos del paciente estaran ligados a las solicitudes y con ellas, a los reportes y pagos. Si va a eliminarlo asegurese que no existan solicitudes asociadas y en estado de procesamiento o finalizadas. ")]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"error","outlined":""},on:{"click":_vm.closeDelete}},[_vm._v(" Cancelar ")]),_c(VBtn,{attrs:{"color":"success"},on:{"click":_vm.deletePatient}},[_vm._v(" Eliminar ")]),_c(VSpacer)],1)],1)],1),_c('PatientFilesModal',{ref:"patient_files_modal"}),_c('PatientDetailsModal',{ref:"patient_details_modal"}),_c('PatientFormModal',{ref:"patient_form_modal",on:{"display-alert":_vm.displayAlert,"reload-patients-list":function($event){return _vm.getPatientsList()}}}),_c('AlertPopUp',{ref:"alert_pop_up"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }