<template>
  <div>
    <v-dialog v-model="showModal" @input="close" width="auto" minHeight="1200">
    <v-card class="d-flex">
      <v-card>
        <v-avatar
          color="primary"
          class="v-avatar-light-bg primary--text ma-2"
          size="300"
          tile
        >
          <v-img
            v-if="patientData.profile_pic"
            :src="patientData.profile_pic"
          ></v-img>
          <span
            v-else
          >{{ 'sin foto' }}</span>
        </v-avatar>
      </v-card>
      <v-card max-width="450">
        <v-card-title class="text-h5 text-center grey lighten-2">
            <v-icon class="me-2" color="primary">
            {{ icons.mdiPaw }}
            </v-icon> 
           {{ patientData.name }} 
        </v-card-title>
        
        <v-divider></v-divider>

        <div class="d-flex mt-3">
          <div>
            <v-card-text>
                <strong class="me-2">{{ 'RAZA:'}}</strong> {{ patientData.race }}
            </v-card-text>

            <v-card-text >
                <strong class="me-2">{{ 'SEXO:'}}</strong> {{ patientData && patientData.sex ? patientData.sex.label : '-'}}
            </v-card-text>

            <v-card-text>
                <strong class="me-2">{{ 'ALTURA:'}}</strong> {{ patientData && patientData.height ? patientData.height: '-' }}
            </v-card-text>

          </div>
          <div>
            <v-card-text>
                <strong class="me-2">{{ 'ESPECIE:'}}</strong> {{  patientData && patientData.specie ? patientData.specie.label : '-' }}
            </v-card-text>

            <v-card-text >
                <strong class="me-2">{{ 'EDAD:'}}</strong> {{ patientData && patientData.birth_date ? computedAge.years + ' año/s' : '-' }}, {{ patientData && patientData.birth_date ? computedAge.months + ' mes/es' : '-'  }}
            </v-card-text>

            <v-card-text>
                <strong class="me-2">{{ 'PESO:'}}</strong> {{ patientData && patientData.weight ? patientData.weight: '-' }}
            </v-card-text>
          </div>
        </div>
        <v-divider></v-divider>
        <v-card-text class="text-center text-xs" >{{ patientData.extra_data }}</v-card-text>
      </v-card>
    </v-card>      
    </v-dialog>
  </div>
</template>

<script>
import { calculateAgeFromDate } from '../../plugins/dateToAgeUtil';
import {
    mdiPaw
} from '@mdi/js'

export default {
  data() {
    return {
      showModal: false,
      loading: true,
      patientData: {}
    }
  },
  methods: {
    async openModal(id) {

    this.loading = true
      try {
          const patient = await this.$api.getPatient(id)

          this.loading = false
          this.showModal = true
          this.patientData = patient.data
          // console.log(this.patientData);

      } catch (e) {
          console.log(e);
      }
    },

    close() {
    this.showModal = false;
    this.loading = true
    },
  },
  computed:{
    computedAge() {
      return calculateAgeFromDate(this.patientData.birth_date);
    },
  },
  setup(){

    return{
      icons: {
          mdiPaw
      },
    }
  }
}
</script>

<style lang="scss" scoped>

</style>