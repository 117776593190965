import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VDialog,{attrs:{"max-width":"500px"},model:{value:(_vm.showFilesDialog),callback:function ($$v) {_vm.showFilesDialog=$$v},expression:"showFilesDialog"}},[_c(VCard,[_c(VCardTitle,[_vm._v(" ARCHIVOS ")]),_c(VList,{attrs:{"rounded":""}},[_c(VListItemGroup,{attrs:{"color":"primary"},model:{value:(_vm.selectedFile),callback:function ($$v) {_vm.selectedFile=$$v},expression:"selectedFile"}},_vm._l((_vm.files),function(file,i){return _c(VListItem,{key:i},[_c(VListItemIcon,[_c(VIcon,{domProps:{"textContent":_vm._s(_vm.icons.mdiFileMultipleOutline)}})],1),_c(VListItemContent,[_c(VListItemTitle,{domProps:{"textContent":_vm._s(file.name)}})],1),_c(VListItemAction,[_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){return _vm.deleteFile(file.id)}}},[_c(VIcon,{attrs:{"color":"error"},domProps:{"textContent":_vm._s(_vm.icons.mdiDelete)}})],1)],1),_c(VListItemAction,[_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){return _vm.downloadFile(file.url)}}},[_c(VIcon,{attrs:{"color":"success"},domProps:{"textContent":_vm._s(_vm.icons.mdiDownload)}})],1)],1)],1)}),1)],1),_c(VDivider),_c('br'),_c(VCardText,[_c('p',[_vm._v(" Agregar Archivos")]),_c(VFileInput,{ref:"file",staticClass:"mt-6",attrs:{"multiple":"","show-size":"","label":"Archivos","hide-details":"","outlined":""},model:{value:(_vm.filesToSend),callback:function ($$v) {_vm.filesToSend=$$v},expression:"filesToSend"}})],1),_c('br'),_c(VCardActions,[_c(VSpacer),_c(VBtn,{staticClass:"mr-2",attrs:{"text":"","outlined":"","color":"primary"},on:{"click":_vm.close}},[_vm._v(" Cancelar ")]),_c(VBtn,{attrs:{"color":"primary","disabled":!_vm.filesToSend.length},on:{"click":_vm.createFile}},[_vm._v(" Añadir ")])],1)],1)],1),_c(VSnackbar,{attrs:{"timeout":_vm.alert.timeout,"color":_vm.alert.color,"elevation":"5","transition":"scale-transition","top":""},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c(VIcon,_vm._b({attrs:{"color":"#fff"},on:{"click":function($event){_vm.alert.showAlert = false}}},'v-icon',attrs,false),[_vm._v(" "+_vm._s(_vm.icons.mdiCloseCircleOutline)+" ")])]}}]),model:{value:(_vm.alert.showAlert),callback:function ($$v) {_vm.$set(_vm.alert, "showAlert", $$v)},expression:"alert.showAlert"}},[_vm._v(" "+_vm._s(_vm.alert.message)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }