<template>
  <div>
    <v-divider class="mt-4"></v-divider>

    <!-- actions -->
    <v-card-text class="d-flex align-center flex-wrap pb-0">
      <!-- search -->
      <v-text-field
      v-model="searchQuery"
      @keyup="handleSearch"
      :append-icon="icons.mdiMagnify"
      placeholder="Buscar por nombre de usuario y empresa"
      outlined
      hide-details
      dense
      class="user-search me-3 mb-4"
      >

      </v-text-field>

      <v-spacer></v-spacer>
      
      <v-spacer></v-spacer>

      <div class="d-flex align-center flex-wrap">
      <v-btn
        color="primary"
        class="mb-4 me-3"
        @click="$refs.patient_form_modal.openModal()"
        >
      <v-icon>{{ icons.mdiPlus }}</v-icon>
      <span>Agregar Paciente</span>
      </v-btn>

    </div>
    </v-card-text>

    <!-- Table -->
    <v-data-table
      :headers="tableColumns"
      :items="patientsListTable"
      :search="search"
      :page="current_page"
      :server-items-length="totalPatientsListTable"
      @update:page="changingPatientsPage($event)"
      @update:items-per-page="changingPatientsItemsPerPage($event)"
      :loading="loading"
      :footer-props="{
        'items-per-page-options': [10, 20, 50],
        'items-per-page-text': 'Items por página',
        'page-text': '{0}-{1} de {2}'
      }"
      class="table-kitchen-sink"
    >

     <!-- NO data -->
     <template v-slot:no-data>
      <v-list-item>
        <v-list-item-title>
          No se encontraron
          <strong>pacientes</strong>
        </v-list-item-title>
      </v-list-item>
    </template>
    <!-- name -->
    <template #[`item.name`]="{item}">
      <div class="d-flex align-center">
        <v-avatar
          color="primary"
          class="v-avatar-light-bg primary--text ma-2"
          size="50"
          @click="$refs.patient_details_modal.openModal(item.id)"
        > 
          <v-img
            v-if="item.profile_pic"
            :src="item.profile_pic"
          ></v-img>
          <span
            v-else
          >{{ '?' }}</span>
        </v-avatar>
        <div 
          class="d-flex flex-column ms-3"
          @click="$refs.patient_details_modal.openModal(item.id)"
        >
          <span class="d-block font-weight-semibold text--primary text-truncate">{{ item.name }}</span>
          <small>{{ item.specie.label }}</small>
        </div>
      </div>
    </template>
    

    <!-- Tutors-->
    <template v-slot:item.tutors="{ item }">
      <div v-if="item.tutors.length">
        <v-chip
          v-for="(tutor, index) in item.tutors"
          class="ms-1"
          :key="index"
          small
          outlined
        >
          {{ tutor.name }}
        </v-chip>
      </div>
      <div v-else class="ms-8"> - </div>
    </template>


    <!-- Files -->
    <template #[`item.files`]="{item}">
      <!-- v-if="item.files.length" -->
      <v-btn 
        class=""
        elevation="2"
        x-small
        outlined
        @click="$refs.patient_files_modal.openModal(item.id)"
      >

        Archivos
      </v-btn>

    </template>
    
    <!-- action -->
    <template #[`item.actions`]="{ item }">
      <v-icon
      class="me-2"
      @click="$refs.patient_form_modal.openModal(item.id)"
      color="primary"
      >
       {{ icons.mdiPencilOutline }}
      </v-icon>
      <v-icon
      @click="deleteModal(item)"
      color="error"
      >
       {{ icons.mdiDeleteOutline }}
      </v-icon>
    </template>
      
    </v-data-table>

    <!-- DELETE dialog -->
    <v-dialog
      v-model="dialogDelete"
      max-width="500px"
      >
      <v-card>
        
        <v-card-title>
          Eliminar Paciente? <b class="ps-3">{{ `'${editedItem.name}'` }}</b>
        </v-card-title>
        <v-card-subtitle
           class="mt-3"
           > 
            Tenga en cuenta que los datos del paciente estaran ligados a las solicitudes y con ellas, a los reportes y pagos. 
            Si va a eliminarlo asegurese que no existan solicitudes asociadas y en estado de procesamiento o finalizadas.
           </v-card-subtitle>
        <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="error"
          outlined
          @click="closeDelete"
        >
          Cancelar
        </v-btn>
        <v-btn
          color="success"
          @click="deletePatient"
        >
          Eliminar
        </v-btn>
        <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <PatientFilesModal 
    ref="patient_files_modal"
    />

    <PatientDetailsModal 
    ref="patient_details_modal"
    />

    <PatientFormModal 
    ref="patient_form_modal"
    @display-alert="displayAlert"
    @reload-patients-list="getPatientsList()"
    />
    
    <AlertPopUp ref="alert_pop_up"/>

  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import AlertPopUp from '../AlertPopUp.vue';
import PatientFormModal from './PatientFormModal.vue';
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiPencilOutline
} from '@mdi/js'
import PatientDetailsModal from './PatientDetailsModal.vue';
import PatientFilesModal from './PatientFilesModal.vue';
import debounce from '../../plugins/debounce.js'


export default {

  data: ()  => {
	return {
    searchQuery: '',
    page_size: 10,
    current_page: 1,
    patientsList: [],
    loading: false,
    dialog:false,
	  dialogDelete:false,
    showFilesDialog: false,
	  editedIndex: -1,
    editedItem: {},
    defaultItem: {}, 
	}
  },
  methods: {
    changingPatientsPage(e) {
      this.current_page = e
      this.getPatientsList()
    },
    changingPatientsItemsPerPage(e) {
      this.page_size = e
      this.getPatientsList()
    },
    handleSearch: debounce(function() {
      this.current_page = 1;
      this.getPatientsList();
    }, 400),
    async getPatientsList(){
      this.loading = true
      try {
        const data = {
          page_size: this.page_size, 
          page: this.current_page,
          search: this.searchQuery,
          summary: true,
        }
        const response = await this.$api.getPatientsList(data)
        // console.log(response)
        this.patientsListTable = response.results
        this.totalPatientsListTable = response.count

      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
	
    },
    deleteModal(item) {
      this.editedIndex = this.patientsList.indexOf(item)
      this.editedItem = { ...item }
      this.dialogDelete = true
	 
      },
    async deletePatient() { 
      try {
        await this.$api.deletePatient(this.editedItem.id)
        this.getPatientsList()
        this.$refs.alert_pop_up.displayAlert({ message: 'Paciente eliminado correctamente', type: 'success' })
        this.close()
      } catch (e) {
        console.log(e)
      }
      this.closeDelete()
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem }
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem }
        this.editedIndex = -1
      })
    },
    //display alerts from childs
    displayAlert(message, type){
      this.$refs.alert_pop_up.displayAlert(message, type)
    }
  },
  watch: {
    dialog(val) {
      // eslint-disable-next-line no-unused-expressions
      val || this.close()
    },
    dialogDelete(val) {
      // eslint-disable-next-line no-unused-expressions
      val || this.closeDelete()
    },
    },
    mounted() {
      this.getPatientsList()
      // console.log(this.getUserRole)
  },
  computed: {
    getUserRole() { return this.$store.getters['session/getUserRole']() },
  },
  setup() {

    const patientsListTable = ref([]);
    const totalPatientsListTable = ref(0);
  
    const tableColumns = [
      { text: 'PACIENTE', value: 'name', align: 'left', sortable: false },
      { text: 'TUTOR', value: 'tutors', align: 'left', sortable: false },
      { text: 'ARCHIVOS', value: 'files', align: 'left', sortable: false },
      { text: 'EDICION', value: 'actions', align: 'center', sortable: false },
    ];

      
  const search = ref("");
  const statusColor = { Confirmed: "primary", Completed: "success", Cancelled: "error" };
    return {
      tableColumns,
      statusColor,
      patientsListTable,
      totalPatientsListTable,
      icons: {
          mdiMagnify,
          mdiDeleteOutline,
          mdiPencilOutline
      },
      search,
    };
  },
  components: { PatientFormModal, PatientDetailsModal, PatientFilesModal, AlertPopUp }
}
</script>

<style lang="scss" scoped>
.table-kitchen-sink ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
}

.v-avatar {
    cursor: pointer;
    transition: transform 0.3s ease;
}

.v-avatar:hover {
    transform: translateY(-3px);
    box-shadow: 0px 0px 5px rgba(38, 5, 51, 0.4);
}
</style>