<template>
  <div>
    <v-dialog
    v-model="showFilesDialog"
    max-width="500px"
    >
      <v-card>
        <v-card-title>
        ARCHIVOS
        </v-card-title>
        <v-list rounded>
          <v-list-item-group
            v-model="selectedFile"
            color="primary"
          >
            <v-list-item
              v-for="(file, i) in files"
              :key="i"
            >
              <v-list-item-icon>
                <v-icon v-text="icons.mdiFileMultipleOutline"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="file.name"></v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn icon @click="deleteFile(file.id)">
                  <v-icon color="error" v-text="icons.mdiDelete"></v-icon>
                </v-btn>
              </v-list-item-action>
              <v-list-item-action>
                <v-btn icon @click="downloadFile(file.url)">
                  <v-icon color="success" v-text="icons.mdiDownload"></v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list-item-group>
        </v-list>
        <v-divider></v-divider>
        <br />
        <v-card-text>
        <p> Agregar Archivos</p>
        <v-file-input class="mt-6"
        ref="file"
        v-model="filesToSend"
        multiple
        show-size
        label="Archivos"
        hide-details
        outlined
        ></v-file-input>
      </v-card-text>
      <br />
      <v-card-actions>
      <v-spacer></v-spacer>
        <v-btn 
        text
        outlined
        color="primary"
        @click="close"
        class="mr-2"
        > 
            Cancelar
        </v-btn>
        <v-btn 
        color="primary"
        @click="createFile"
        :disabled="!filesToSend.length"
        >
        Añadir
        </v-btn>
        
      </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
    v-model="alert.showAlert"
    :timeout="alert.timeout"
    :color="alert.color"
    elevation="5"
    transition="scale-transition"
    top
    >
      {{ alert.message }}

      <template #action="{ attrs }">
        <v-icon
        color="#fff"
          v-bind="attrs"
          @click="alert.showAlert = false"
        >
       {{icons.mdiCloseCircleOutline}}
        </v-icon>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import {
  mdiFileMultipleOutline,
  mdiDelete,
  mdiDownload,
  mdiCloseCircleOutline
} from '@mdi/js'
 
export default {
  data() {
    return {
      showFilesDialog: false,
      patientId: -1,
      files: [],
      selectedFile: 0,
      filesToSend: [],
      showAlert: false,
      responseMessage: '',

      alert: {
      showAlert: false,
      message: '',
      color: '',
      timeout: 3000
      },
    }
  },
  methods: {
    async openModal(patient_id) {
      this.showFilesDialog = true
      this.patientId = patient_id
      await this.getFiles()

    },
    async createFile(){
      const formData = new FormData();

      if (this.filesToSend){
      for (let i = 0; i < this.filesToSend.length; i++) {
          formData.append('files', this.filesToSend[i])
        }
      }
      try {
        const response = await this.$api.createFiles(this.patientId, formData)
        // console.log(response)
        await this.getFiles()
        this.filesToSend = []
        this.displayAlert({type: 'success', message:'Se agregaron archivos'})
      } catch (error) {       
        this.displayAlert({type: 'error', message: 'Ha ocurrido un error'})
        console.log(error)
      }
    },
    async getFiles() {
      try {
        const response = await this.$api.getPatientFiles(this.patientId)
        this.files = response.data.files
      } catch(error) {
        console.log(error)
      }
    },
    downloadFile(url){
      // console.log(url)
      window.open(url, '_blank')
      // window.location = url
    },
    async deleteFile(file_id){
      try {
        const response = await this.$api.deletePatientFiles(this.patientId,{id: file_id})
        await this.getFiles()
        this.displayAlert({type: 'success', message: 'Archivo eliminado con éxito'})
      } 
      catch(error) {
        this.displayAlert({type: 'error', message: 'Ha ocurrido un error'})
      }
    },
    close () {
      this.showFilesDialog = false
      this.filesToSend = []
    },
    displayAlert(payload){
      this.alert.showAlert = true;
      this.alert.message = payload.message;
      this.alert.color = payload.type === 'success' ? 'success' : 'error';
    }
  },
  setup() {
    
    return {
      icons:{
        mdiFileMultipleOutline,
        mdiDelete,
        mdiDownload,
        mdiCloseCircleOutline
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>