<template>
    <div>
      <v-card class="mb-6">
        <v-card-title>Pacientes</v-card-title>
        <ListPatients />
      </v-card>
  
    </div>
  </template>
  
  <script>
import ListPatients from '@/components/Patients/ListPatients.vue';

  export default {
   components: {
    ListPatients
},
   setup() {
      return {
        // icons
        icons: {
          
        },
      }
    },
  
  }
  </script>